i.pi.pi-bars {
  color: #fff;
  font-size: 20px;
}
@media screen and (max-width: 960px) {
  .p-menubar .p-menubar-root-list {
    position: absolute;
    padding: 0.2rem 0;
    font-size: 18px !important;
    background: #393b3a !important;
  }
  span.dropdown-toggle.active {
    font-size: 12px !important;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-xl {
    padding-right: 0px !important;
    padding-left: 5px !important;
  }
  .right-section img {
    width: 40px;
    border-radius: 20% !important;
    margin: 0px 30px !important;
  }
}
/* 
.p-menubar .p-submenu-list {
  padding: 0.25rem 0;
  box-shadow: 0 1px 5px -1px rgba(0,0,0,0.3) !important;
  background: rgba(74,75,75,0.9) !important;} */

.dropdown-toggle {
  white-space: nowrap;
  font-size: 18px !important;
}
.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link:not(.p-disabled):hover {
  background: #0b4713 !important;
  color: #000 !important;
}
.p-menubar
  .p-menubar-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link,
.p-menubar
  .p-menubar-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover {
  background: #0b4713 !important;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
  padding: 0.9rem !important;
}
/* accodion  start*/
.access-right label {
  padding: 0px;
  display: inline-block;
}
.access-right h4 {
  font-size: 1.3rem;
}
.access-right .accordion-item {
  margin-bottom: 20px;
  border-radius: 0px !important;
  box-shadow: 0px 1px 4px 0px #bfbfbf;
}

.access-right .accordion-button[aria-expanded="true"] {
  background-color: #fcfcfc;
  color: #404040;
  /*   box-shadow:0px 1px 2px 2px #fffff7; */
}
.access-right .accordion-button:after {
  color: #404040;
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: #e6e9e9 !important;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color);
}
.accordion-header:hover {
  background-color: #090;
}

.access-right1 div {
  border: 1px solid black;
  margin: auto;
  width: 400px;
  padding: 5px 20px;
}
.access-right1 p {
  text-align: center;
}
.access-right1 a {
  text-decoration: none;
  font-size: 30px;
}
.access-right1 input {
  margin-right: 0.5rem;
}
/* accodion end */

.dashboard-data {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid #fff !important;
  opacity: 1;
}

.counte {
  text-align: right;
}
.scane {
  text-align: left;
}
.react-confirm-alert-body {
  text-align: center !important;
}
.react-confirm-alert-body h1 {
  font-size: 1.4rem !important;
}
.react-confirm-alert-button-group > button {
  outline: none;
  background: green !important;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #fff !important;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 15px !important;
  cursor: pointer;
}
.react-confirm-alert-button-group {
  text-align: center;
  display: flex;
  justify-content: center !important;
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #6c757d;
  margin-left: 0rem !important;
}
span.p-button-label.p-c {
  color: #fff;
}
.col-md-6.custom-breadcrumb .master {
  width: 50%;
  margin-top: 0px;
}
.col-md-6.heading .master {
  width: 50%;
  margin-top: 5px;
}
.col-md-6.heading.report {
  width: 50%;
}
.col-md-6.custom-breadcrumb.report {
  width: 50%;
}
.p-button.p-button-warning,
.p-buttonset.p-button-warning > .p-button,
.p-splitbutton.p-button-warning > .p-button {
  color: #ffffff !important;
  background: #fbc02d;
  border: 1px solid #fbc02d;
}

.epch-bg {
  background: #fff;
  /* background: linear-gradient(to bottom, #d2f0e6 0%, #c5ebde 100%); */
}
.dash-data {
  height: 100% !important;
}
.nav-menu {
  margin: -6px 0;
}
.mapped-count {
  font-size: 1.2rem;
  color: #ffa001;
  font-weight: bold;
}
.qr-boxx {
  margin: 2rem !important;
  width: 60% !important;
}
.nav-link {
  border-radius: 5px !important;
}
a.nav-link.active {
  padding: 0px;
}
.qr-detail label {
  padding: 5px;
  display: inline-block;
}
.usermaster label {
  padding: 5px;
  display: inline-block;
}
.product-imge img {
  display: flex;
}
select#epch {
  border: 1px solid #ccc !important;
  margin: 0 0 4px 0;
  padding: 8px;
}
option {
  padding: 20px 0 !important;
}
ul.dropdown-menu.dropdown-menu-end.show {
  width: 220px;
  text-align: center;
  right: -30px;
  font-size: 15px;
  border-top: none;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.navbar-light .navbar-nav .nav-link {
  color: #000;
  text-transform: uppercase;
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: #000;
}
.nav-link:hover {
  background: #ffa001 !important;
  color: #fff !important;
  /* padding: 10px !important; */
  /* transition: 0.3s; */
  border-radius: 5px !important;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 3rem !important;
  height: 3rem !important;
  color: #ffffff !important;
  border: 0 none;
  border: 1px solid #fff !important;
  background: #34643a !important;
  border-radius: 50% !important;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
  /* box-shadow: 0 8px 20px 0 rgb(0 0 0 / 15%);
-o-box-shadow: 0 8px 20px 0 rgba(0,0,0,.15);
-ms-box-shadow: 0 8px 20px 0 rgba(0,0,0,.15);
-moz-box-shadow: 0 8px 20px 0 rgba(0,0,0,.15); */
}
.p-dialog .p-dialog-header {
  border-bottom: 0 none;
  background: #ffffff;
  color: #495057;
  padding: 0.5rem !important;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.p-fileupload .p-fileupload-content {
  background: #ffffff;
  padding: 0px !important;
  border: 1px solid #dee2e6;
  color: #495057;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.error {
  font-size: 12px !important;
  color: red !important;
}
/* navbar */

/* Animated */
.button {
  text-decoration: none;
  font-size: 20px;
  background: linear-gradient(to bottom, #d2f0e6 0%, #c5ebde 100%);
  color: #34643a;
  border: 3px solid #34643a;
  position: relative;
}
.button::before,
.button::after {
  content: " ";
  position: absolute;
  width: 40px;
  height: 40px;
  border: inherit;
  transition: all 0.5s;
}
.button::before {
  top: -15px;
  left: -15px;
  border-width: 3px 0 0 3px;
}
.button::after {
  bottom: -15px;
  right: -15px;
  border-width: 0 3px 3px 0;
}
.button:hover::before,
.button:hover::after {
  width: calc(100% + 27px);
  height: calc(100% + 27px);
}
/* Infobox ===================================== */
.info-box {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  height: 80px;
  display: flex;
  cursor: default;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  /* margin-bottom: 30px; */
}

.info-box .icon {
  display: inline-block;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.12);
  width: 80px;
}
.info-box .icon i {
  color: #34643a;
  font-size: 40px;
  line-height: 80px;
}
.info-box .icon .chart.chart-bar {
  height: 100%;
  line-height: 100px;
}
.info-box .icon .chart.chart-bar canvas {
  vertical-align: baseline !important;
}
.info-box .icon .chart.chart-pie {
  height: 100%;
  line-height: 123px;
}
.info-box .icon .chart.chart-pie canvas {
  vertical-align: baseline !important;
}
.info-box .icon .chart.chart-line {
  height: 100%;
  line-height: 115px;
}
.info-box .icon .chart.chart-line canvas {
  vertical-align: baseline !important;
}
.info-box .content {
  display: inline-block;
  padding: 7px 10px;
  font-size: 25px;
  color: #000;
}
.info-box .content .text {
  font-size: 18px;
  margin-top: 5px;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.info-box .content .number {
  font-weight: normal;
  font-size: 26px;
  margin-top: -4px;
  color: #555;
}

.info-box.hover-zoom-effect .icon {
  overflow: hidden;
}
.info-box.hover-zoom-effect .icon i {
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.info-box.hover-zoom-effect:hover .icon i {
  opacity: 0.4;
  -moz-transform: rotate(-32deg) scale(1.4);
  -ms-transform: rotate(-32deg) scale(1.4);
  -o-transform: rotate(-32deg) scale(1.4);
  -webkit-transform: rotate(-32deg) scale(1.4);
  transform: rotate(-32deg) scale(1.4);
}

.info-box.hover-expand-effect:after {
  background-color: rgba(0, 0, 0, 0.05);
  content: ".";
  position: absolute;
  left: 80px;
  top: 0;
  width: 0;
  height: 100%;
  color: transparent;
  -moz-transition: all 0.95s;
  -o-transition: all 0.95s;
  -webkit-transition: all 0.95s;
  transition: all 0.95s;
}

.info-box.hover-expand-effect:hover:after {
  width: 100%;
}

.info-box-2 {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  height: 80px;
  display: flex;
  cursor: default;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}
.info-box-2 .icon {
  display: inline-block;
  text-align: center;
  width: 80px;
}
.info-box-2 .icon i {
  color: #fff;
  font-size: 50px;
  line-height: 80px;
}
.info-box-2 .chart.chart-bar {
  height: 100%;
  line-height: 105px;
}
.info-box-2 .chart.chart-bar canvas {
  vertical-align: baseline !important;
}
.info-box-2 .chart.chart-pie {
  height: 100%;
  line-height: 123px;
}
.info-box-2 .chart.chart-pie canvas {
  vertical-align: baseline !important;
}
.info-box-2 .chart.chart-line {
  height: 100%;
  line-height: 115px;
}
.info-box-2 .chart.chart-line canvas {
  vertical-align: baseline !important;
}
.info-box-2 .content {
  display: inline-block;
  padding: 7px 10px;
}
.info-box-2 .content .text {
  font-size: 13px;
  margin-top: 11px;
  color: #555;
}
.info-box-2 .content .number {
  font-weight: normal;
  font-size: 26px;
  margin-top: -4px;
  color: #555;
}

.info-box-2.hover-zoom-effect .icon {
  overflow: hidden;
}
.info-box-2.hover-zoom-effect .icon i {
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.info-box-2.hover-zoom-effect:hover .icon i {
  opacity: 0.4;
  -moz-transform: rotate(-32deg) scale(1.4);
  -ms-transform: rotate(-32deg) scale(1.4);
  -o-transform: rotate(-32deg) scale(1.4);
  -webkit-transform: rotate(-32deg) scale(1.4);
  transform: rotate(-32deg) scale(1.4);
}

.info-box-2.hover-expand-effect:after {
  background-color: rgba(0, 0, 0, 0.05);
  content: ".";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  color: transparent;
  -moz-transition: all 0.95s;
  -o-transition: all 0.95s;
  -webkit-transition: all 0.95s;
  transition: all 0.95s;
}

.info-box-2.hover-expand-effect:hover:after {
  width: 100%;
}

.info-box-3 {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  height: 80px;
  display: flex;
  cursor: default;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}
.info-box-3 .icon {
  position: absolute;
  right: 10px;
  bottom: 2px;
  text-align: center;
}
.info-box-3 .icon i {
  color: rgba(0, 0, 0, 0.15);
  font-size: 60px;
}
.info-box-3 .chart {
  margin-right: 5px;
}
.info-box-3 .chart.chart-bar {
  height: 100%;
  line-height: 50px;
}
.info-box-3 .chart.chart-bar canvas {
  vertical-align: baseline !important;
}
.info-box-3 .chart.chart-pie {
  height: 100%;
  line-height: 34px;
}
.info-box-3 .chart.chart-pie canvas {
  vertical-align: baseline !important;
}
.info-box-3 .chart.chart-line {
  height: 100%;
  line-height: 40px;
}
.info-box-3 .chart.chart-line canvas {
  vertical-align: baseline !important;
}
.info-box-3 .content {
  display: inline-block;
  padding: 7px 16px;
}
.info-box-3 .content .text {
  font-size: 13px;
  margin-top: 11px;
  color: #555;
}
.info-box-3 .content .number {
  font-weight: normal;
  font-size: 26px;
  margin-top: -4px;
  color: #555;
}

.info-box-3.hover-zoom-effect .icon i {
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.info-box-3.hover-zoom-effect:hover .icon i {
  opacity: 0.4;
  -moz-transform: rotate(-32deg) scale(1.4);
  -ms-transform: rotate(-32deg) scale(1.4);
  -o-transform: rotate(-32deg) scale(1.4);
  -webkit-transform: rotate(-32deg) scale(1.4);
  transform: rotate(-32deg) scale(1.4);
}

.info-box-3.hover-expand-effect:after {
  background-color: rgba(0, 0, 0, 0.05);
  content: ".";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  color: transparent;
  -moz-transition: all 0.95s;
  -o-transition: all 0.95s;
  -webkit-transition: all 0.95s;
  transition: all 0.95s;
}

.info-box-3.hover-expand-effect:hover:after {
  width: 100%;
}

.info-box-4 {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  height: 80px;
  display: flex;
  cursor: default;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}
.info-box-4 .icon {
  position: absolute;
  right: 10px;
  bottom: 2px;
  text-align: center;
}
.info-box-4 .icon i {
  color: rgba(0, 0, 0, 0.15);
  font-size: 60px;
}
.info-box-4 .chart {
  margin-right: 5px;
}
.info-box-4 .chart.chart-bar {
  height: 100%;
  line-height: 50px;
}
.info-box-4 .chart.chart-bar canvas {
  vertical-align: baseline !important;
}
.info-box-4 .chart.chart-pie {
  height: 100%;
  line-height: 34px;
}
.info-box-4 .chart.chart-pie canvas {
  vertical-align: baseline !important;
}
.info-box-4 .chart.chart-line {
  height: 100%;
  line-height: 40px;
}
.info-box-4 .chart.chart-line canvas {
  vertical-align: baseline !important;
}
.info-box-4 .content {
  display: inline-block;
  padding: 7px 16px;
}
.info-box-4 .content .text {
  font-size: 13px;
  margin-top: 11px;
  color: #555;
}
.info-box-4 .content .number {
  font-weight: normal;
  font-size: 26px;
  margin-top: -4px;
  color: #555;
}

.info-box-4.hover-zoom-effect .icon i {
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.info-box-4.hover-zoom-effect:hover .icon i {
  opacity: 0.4;
  -moz-transform: rotate(-32deg) scale(1.4);
  -ms-transform: rotate(-32deg) scale(1.4);
  -o-transform: rotate(-32deg) scale(1.4);
  -webkit-transform: rotate(-32deg) scale(1.4);
  transform: rotate(-32deg) scale(1.4);
}

.info-box-4.hover-expand-effect:after {
  background-color: rgba(0, 0, 0, 0.05);
  content: ".";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  color: transparent;
  -moz-transition: all 0.95s;
  -o-transition: all 0.95s;
  -webkit-transition: all 0.95s;
  transition: all 0.95s;
}

.info-box-4.hover-expand-effect:hover:after {
  width: 100%;
}
.bg-pink {
  background-color: #fff !important;
  color: #fff;
}
.bg-cyan {
  background-color: #ff9800 !important;
  color: #fff;
}
.bg-light-green {
  background-color: #ff9800 !important;
  color: #fff;
}
.bg-orange {
  background-color: #ff9800 !important;
  color: #fff;
}

.tab-header {
  cursor: pointer;
  padding: 10px;
  background-color: #f0f0f0;
}
.tab-content {
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #ccc;
}
.feature-info {
  background: #fff;
  padding: 20px 35px;
  margin-top: 5rem;
}
/* Infobox - End ====================================== */
.cards {
  background: #ffffff;
  padding: 1.25rem;
  border: 0 none;
  margin-bottom: 2rem;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.p-breadcrumb ul li:last-child .p-menuitem-text {
  color: #ff7a00 !important;
  font-size: 15px !important;
}
.heading h3 {
  font-size: 1.6rem;
  margin-bottom: 0rem !important;
}

.mt5 {
  margin-top: 1rem !important;
}
.count .widget-chart .widget-subheading:first-child {
  font-size: 16px;
  margin-top: 0;
}
.count .icon-wrapper {
  width: 40px;
  height: 40px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}
.count .icon-wrapper i {
  margin: 0 auto;
  font-size: 1.5rem;
  position: relative;
  z-index: 5;
}
.count .bg-warning {
  background-color: #ffa001 !important;
}
.count .widget-chart .widget-subheading {
  margin: 0px;
  display: flex;
  opacity: 0.6;
  justify-content: center;
  text-transform: uppercase;
}
li.fa.fa-qrcode.text-white.mapping {
  font-size: 2rem;
}
.p-card .p-card-content {
  padding: 0rem 0 !important;
}
.p-breadcrumb {
  background: #ffffff;
  border: none !important;
  border-radius: 3px;
  padding: 1rem;
  display: flex !important;
  justify-content: end !important;
}
.p-inputtext {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  color: #495057;
  background: #ffffff;
  padding: 0.2rem 0.5rem !important;
  border: 1px solid #ced4da;
  -webkit-transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    -webkit-box-shadow 0.2s;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    -webkit-box-shadow 0.2s;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s, -webkit-box-shadow 0.2s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 3px;
}
.p-button {
  color: #ffffff;
  background: #2196f3;
  border: 1px solid #2196f3;
  padding: 0.2em 1rem !important;
  font-size: 0.5rem;
  -webkit-transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    -webkit-box-shadow 0.2s;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    -webkit-box-shadow 0.2s;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s, -webkit-box-shadow 0.2s;
  border-radius: 3px;
}
/* popup */
thead.p-datatable-thead {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #495057;
  background: #f8f9fa;
  -webkit-transition: -webkit-box-shadow 0.2s;
  transition: -webkit-box-shadow 0.2s;
  transition: box-shadow 0.2s;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
}
.center-block {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.qr-box .MuiFormControl-root {
  border: 0;
  margin: 0;
  display: inline-flex;
  padding: 2px !important;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
}
.mt3 {
  margin-top: 1.2rem !important;
  /* m */
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
  border-bottom: 1px solid #ccc !important;
}

/* @media (min-width: 576px){
.modal-dialog {
  max-width: 700px !important;
  margin: 1.75rem auto;}
} */
.qr-data .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff !important;
  background-clip: padding-box;
  border: 2px solid #ced4da !important;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-info {
  color: #fff !important;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff !important;
  background-color: #138496;
  border-color: #117a8b;
}

img.img-fluid.epchlogo {
  width: 42%;
}
.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0rem !important;
}

body {
  /* overflow: hidden; */
  overflow-x: hidden;
  width: 100vw;
  background: #e3eded !important;
  /* overflow-y: scroll; */
}

::placeholder {
  color: #ffffff !important;
  opacity: 0.4 !important;
}

img.img-fluid.epch-logo {
  width: 25%;
}

.col-md-6.text-right1 {
  text-align: right;
}

img.user-avatar.rounded-circle.accimg {
  width: 4%;
}

.footer {
  background: rgba(0, 11, 30, 0.8);
  color: #fff;
  text-align: center;
  padding: 10px 20px;
  font-size: 13px;
  position: fixed;
  bottom: 0;
  width: 101vw;
  z-index: 8;
}

.desinged {
  color: #cef9f9;
}

.desinged:hover {
  color: #f1f3ae;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  padding: 0px 10px !important;
  z-index: 1030;
}

.container1 {
  color: var(--text-color);
  background-image: url("assets/images/SignIn.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  margin-top: -23px;
  position: fixed;
}

.checkbox-data label {
  padding: 0px !important;
  display: inline-block;
}

.container1 .get-form {
  justify-content: center;
  /* height: 100%; */
  position: absolute;
  left: 0;
  margin: 11% auto;
  padding: 10px;
  position: relative;
}
.container1 .h4 {
  font-size: 1.2rem !important;
}
.container1 .h5 {
  font-size: 1.1rem !important;
}

/* get-form section1 */
.get-form .button-switch {
  background-color: var(--link-color);
  width: fit-content;
  border-radius: 10px;
  margin-bottom: 10px;
}

.get-form .button-switch button {
  background-color: var(--link-color);
  border: 3px solid var(--link-color);
  border-radius: 10px;
  padding: 7px 20px;
  font-weight: 500;
  color: var(--text-color);
  transition: all 1s ease;
}

.get-form .button-switch button.active {
  background-color: #7784ce;
  font-weight: 700;
}

/* .get-form section2 oprator */

#signup,
#signin {
  width: 32em;
}

/* get-form section2 Form SignIn*/
.get-form form .menuBox {
  justify-content: space-between;
  font-weight: 500;
}

.get-form form .menuBox a {
  font-weight: 400;
  color: var(--link-color);
  font-size: 0.8em;
}

.get-form form .menuBox a:hover {
  color: #7784ce;
}

/* get-form section2 Form SignUp*/
.form-input .box-input {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
}

.form-input .box-input .inputBox {
  margin: 0 !important;
}

/* get-form section3 */
.more-option {
  margin-top: 30px;
  width: 100%;
}

.get-form .paragraf p {
  color: #d5d2d2;
  position: relative;
  text-align: center;
  z-index: 3;
}

.get-form .paragraf p::after,
.get-form .paragraf p::before {
  position: absolute;
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.get-form .paragraf p::after {
  width: 100%;
  border-top: 1px solid var(--link-color);
  z-index: -2;
}

.get-form .paragraf p::before {
  height: 19px;
  background-color: var(--main-color);
  width: 12em;
  z-index: -1;
}

.get-form .boxIcon {
  justify-content: center;
  position: relative;
  margin: 1.6em 0 1em;
  gap: 1em;
}

.get-form .boxIcon .Ficon {
  font-size: 1.9em;
}

/* Animation */
@media (min-width: 592px) {
  .selected {
    animation: slide-in 0.5s forwards;
  }

  .dismiss {
    animation: slide-out 0.5s forwards;
  }

  @keyframes slide-in {
    0% {
      left: 0;
    }

    100% {
      left: calc(100% - (5em + 32em));
    }
  }

  @keyframes slide-out {
    0% {
      left: calc(100% - (5em + 32em));
    }

    100% {
      left: 0;
    }
  }
}

.mt7 {
  margin-top: 6rem !important;
}

.pdflalel label {
  padding: 5px;
  display: inline-block;
}
.pdflalel .form-group {
  margin-bottom: 0rem;
}
a.nav-link {
  cursor: pointer !important;
}
/* Responsif */

@media (max-width: 591px) {
  .more-option {
    margin-bottom: 50%;
    width: 100%;
  }

  .mt3 {
    margin-top: 0rem !important;
  }

  .right-section {
    display: inline-block;
    width: auto !important;
    text-align: right !important;
    padding: 0px !important;
    margin-top: -70px !important;
    margin-bottom: 5px;
    color: #fff;
  }

  .full-width {
    width: 100%;
    padding: 0 5px !important;
  }

  .qr-box {
    width: 95% !important;
  }

  .container {
    display: block;
  }

  #signup,
  #signin {
    width: 100%;
  }

  .get-form {
    width: 100% !important;
  }

  .get-form .paragraf p::before {
    background-color: rgba(45, 50, 80, 0.71) !important;
  }

  .get-form .paragraf p::after,
  .get-form .paragraf p::before {
    top: -23px;
  }

  .get-form .paragraf p::before {
    height: 0;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
}

h1 {
  font-weight: 500;
  color: var(--text-color);
}

p,
span {
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

textarea:focus,
input:focus {
  outline: none;
  border-color: var(--text-color) !important;
}

.flex {
  display: flex;
  align-items: center;
}

.hiden {
  display: none;
}

:root {
  --main-color: #2d3250;
  --button-color: #f8b179;
  --text-color: #ffffff;
  --link-color: #676f9d;
}

/* Button */
form :where(button[type="submit"], button.submit, span.submit) {
  padding: 0.2em 3.5em;
  font-weight: 600;
  border-radius: 5px !important;
  letter-spacing: 1px;
  color: #fff;
  background-color: green;
  border: none;
  border-radius: 7px;
  margin-top: 1em;
  margin-right: 1em;
  font-size: 1.2em !important;
}

/* Form */
.get-form {
  flex-direction: column;
  background-color: #ffff;
  padding: 1rem 2em;
}

.hlogin {
  color: green;
}

/* Titel */
.get-form form h1 span {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.get-form form p {
  width: 80%;
  font-size: 0.9em;
  margin-bottom: 2em;
  color: var(--text-color);
}

/* Input */
.get-form form .form-input .inputBox {
  margin: 1.5em 0;
  position: relative;
}

.get-form form .form-input .inputBox :where(label, input) {
  padding: 1.2em 1em;
  font-size: 1em;
}

.get-form form .form-input .inputBox label {
  position: absolute;
  font-weight: 500;
  top: 1px;
  left: 1px;
  transition: 0.3s;
  color: rgba(255, 255, 255, 0.432);
  pointer-events: none;
  z-index: 1;
}

.form-input .inputBox label::before {
  position: absolute;
  z-index: -1;
  content: "";
  width: 0;
  left: 0;
  height: 10px;
  top: 10px;
  background-color: transparent;
  transition: 0.5s;
}

.get-form form .form-input .inputBox input {
  border-radius: 5px;
  width: 100%;
  border: 2px solid rgba(1, 94, 29, 0.815) !important;
  /* background-color: var(--link-color); */
  color: var(--text-color);
  font-weight: 500;
}

.form-input .inputBox input:focus ~ label,
.form-input .inputBox input:valid ~ label,
.form-input .inputBox input:not([value=""]) ~ label {
  padding: 0;
  top: -10px;
  left: 23px;
  color: green;
}

.form-input .inputBox input:focus ~ label::before,
.form-input .inputBox input:valid ~ label::before,
.form-input .inputBox input:not([value=""]) ~ label::before {
  width: 110%;
  left: -5%;
  background-color: #fff;
}

@media (max-width: 433px) {
  form :where(h1, p) {
    text-align: center;
    width: 100% !important;
  }

  .form-input .box-input {
    grid-template-columns: 1fr;
  }
}

.row1 {
  margin-top: -25px;
  margin-bottom: 50px;
}

.row1 {
  margin-left: 0;
  margin-right: 0;
}

.header-top {
  background: #fff;
  color: #797979;
  /* box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.3); */
  position: fixed;
  width: 100%;
  z-index: 9;
  top: 0;
}

.header-top {
  position: relative;
  left: 0;
}

.full-width {
  width: 100%;
  padding: 0 35px;
}

.logo-section {
  width: 335px;
  display: inline-block;
  padding: 4px 20px 4px 0;
  vertical-align: top;
}

.logo-section img {
  height: 70px;
}

.logo-section {
  width: 100%;
  text-align: center;
}

.right-section {
  /* width: 100%; */
  text-align: center;
  padding: 0px 15px 0 20px;
}

.right-section {
  display: inline-block;
  /* width: calc(100% - 335px); */
  text-align: right;
  padding: 18px 15px 0 20px;
  margin-bottom: 0;
  color: #f7af11;
}

.right-section img {
  width: 40px;
  border-radius: 20% !important;
  /* margin: 0 10px; */
}

.right-section .heading,
.heading2 {
  border-bottom: 1px solid #000;
  padding-bottom: 15px;
  font-size: 15px;
  margin-bottom: 12px;
  font-weight: bold;
}

.right-section .heading2 {
  padding-bottom: 0 !important;
}

.user-area .dropdown-toggle::after {
  display: inline-block;
  margin-right: 4px;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.user-area {
  padding-bottom: 1px;
  width: 335px;
  float: right;
}

.user-area:hover .user-menu {
  display: block;
}

.user-area {
  width: 100%;
}

.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #a8cf45;
  border-color: #a8cf45;
  box-shadow: none;
}

#left-panel li .dropdown-toggle::after {
  right: 10px;
  position: absolute;
  top: 23px;
}

.left-panel .dropdown-toggle::after {
  border: none;
}

.user-menu .nav-link a {
  color: #fff;
  text-decoration: none !important;
}
.navbar-brand {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}

.p-datatable-scrollable-wrapper {
  font-size: 14px !important;
}

.user-menu {
  display: none;
  position: absolute;
  text-align: left;
  padding: 10px;
  width: 200px;
  color: #fff;
  background: #fff;
  right: -3px !important;
  z-index: 9;
  margin-top: 0px;
  /* box-shadow: 0 0 4px rgb(0 0 0 / 20%); */
}
.popup1 {
  width: 46vw;
}
.update .col-md-4 {
  text-align: right;
}
.qrdetail .col-md-4 {
  text-align: right;
}
@media (max-width: 768px) {
  .user-area {
    width: 0% !important;
  }
  .mt5 {
    margin-top: 1rem !important;
    margin-bottom: 6rem !important;
  }
  .col-md-6.custom-breadcrumb .master {
    width: 100%;
    margin-bottom: 0rem;
  }
  .col-md-6.heading .master {
    width: 100%;
    margin-bottom: 1rem;
  }

  .col-md-6.heading.report {
    width: 100%;
  }
  .col-md-6.custom-breadcrumb.report {
    width: 100%;
    margin-top: 2px !important;
  }
  .update .col-md-4 {
    text-align: left;
  }
  .qrdetail .col-md-4 {
    text-align: left;
  }

  .popup1 {
    width: 90vw;
  }
  ul.dropdown-menu.dropdown-menu-end.show {
    width: 100%;
    text-align: center;
    right: -50px;
    border-top: none;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }
  .heading h3 {
    font-size: 1.2rem;
    margin-bottom: 0rem !important;
  }
  .p-breadcrumb {
    background: #ffffff;
    border: 1px solid #dee2e6;
    border-radius: 3px;
    padding: 0rem !important;
  }
  .counte {
    text-align: center;
    font-size: 0.7rem;
  }
  .scane {
    text-align: center;
    font-size: 1rem;
  }

  .qr-boxx {
    margin: 0.5rem 0 0rem 0 !important;
    width: 95% !important;
  }
  .user-menu {
    display: none;
    position: absolute;
    text-align: left;
    padding: 10px;
    width: 215px !important;
    color: #fff;
    background: #fff;
    right: -10px !important;
    z-index: 9;
    margin-top: 0px;
    /* box-shadow: 0 0 4px rgb(0 0 0 / 20%); */
  }
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: #fff;
  background-color: #ffa001 !important;
}

.user-menu .nav-link:hover,
.user-menu .nav-link:hover a {
  background-color: #ffa001 !important;
  text-decoration: none;
  color: #fff !important;
  border-radius: 5px !important;
}

.user-menu .nav-link {
  padding: 10px 12px;

  cursor: pointer;
}

.user-menu .nav-link .fa {
  padding-right: 8px;
}

.user-area:hover .user-menu {
  display: block;
}

.open .sidebar-wraper .fa {
  font-size: 19px;
}

.sidebar-wraper a .fa {
  margin-right: 12px;
}

.p-datatable-emptymessage {
  text-align: center;
}

.tblAuto .p-datatable-wrapper > table {
  border-collapse: collapse !important;
  width: 100% !important;
  table-layout: auto !important;
}

.tblAuto .p-datatable-thead > tr > th:last-child {
  width: 120px !important;
}

/* .p-datatable table {
margin-bottom: 30px;
} */

body .p-datatable .p-datatable-tbody > tr > td {
  padding: 0.7em 0.857em !important;
  text-align: left;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
}

body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active {
  background-color: #191919 !important;
  color: #f3c500 !important;
}

body .p-dropdown .p-dropdown-label {
  padding-right: 2.7em !important;
}

body .p-dropdown .p-dropdown-label {
  padding-right: 20px !important;
  padding-left: 5px !important;
}
.p-datatable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-paginator {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.p-component,
.p-component * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.p-paginator {
  background: #ffffff;
  color: #6c757d;
  border: solid #e9ecef;
  border-width: 0;
  padding: 0.5rem 1rem;
  border-radius: 3px;
}
button.p-paginator-first.p-paginator-element.p-link.p-disabled {
  background-color: transparent;
  border: 0 none;
  color: #6c757d;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  padding: 0 0.5rem;
}
button.p-paginator-first.p-paginator-element.p-link.p-disabled {
  background-color: transparent;
  border: 0 none;
  color: #6c757d;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  -webkit-transition: -webkit-box-shadow 0.2s;
  transition: -webkit-box-shadow 0.2s;
  transition: box-shadow 0.2s;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
  border-radius: 3px;
}
button.p-paginator-prev.p-paginator-element.p-link.p-disabled {
  background-color: transparent;
  border: 0 none;
  color: #6c757d;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  -webkit-transition: -webkit-box-shadow 0.2s;
  transition: -webkit-box-shadow 0.2s;
  transition: box-shadow 0.2s;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
  border-radius: 3px;
}
button.p-paginator-next.p-paginator-element.p-link.p-disabled {
  background-color: transparent;
  border: 0 none;
  color: #6c757d;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  -webkit-transition: -webkit-box-shadow 0.2s;
  transition: -webkit-box-shadow 0.2s;
  transition: box-shadow 0.2s;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
  border-radius: 3px;
}
button.p-paginator-last.p-paginator-element.p-link.p-disabled {
  background-color: transparent;
  border: 0 none;
  color: #6c757d;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  -webkit-transition: -webkit-box-shadow 0.2s;
  transition: -webkit-box-shadow 0.2s;
  transition: box-shadow 0.2s;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
  border-radius: 3px;
}
.p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled {
  display: -ms-inline-flexbox;
  display: inline-flex;
  cursor: pointer;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: 0.5rem;
  /* height: 1.9rem; */
  background: #ffffff;
  border: 1px solid #ced4da;
  -webkit-transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    -webkit-box-shadow 0.2s;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    -webkit-box-shadow 0.2s;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s, -webkit-box-shadow 0.2s;
  border-radius: 3px;
}
button.p-paginator-next.p-paginator-element.p-link {
  background-color: transparent;
  border: 0 none;
  color: #6c757d;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  -webkit-transition: -webkit-box-shadow 0.2s;
  transition: -webkit-box-shadow 0.2s;
  transition: box-shadow 0.2s;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
  border-radius: 3px;
}
button.p-paginator-last.p-paginator-element.p-link {
  background-color: transparent;
  border: 0 none;
  color: #6c757d;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  -webkit-transition: -webkit-box-shadow 0.2s;
  transition: -webkit-box-shadow 0.2s;
  transition: box-shadow 0.2s;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
  border-radius: 3px;
}
button.p-paginator-page.p-paginator-element.p-link.p-paginator-page-start.p-highlight {
  background: #e3f2fd;
  border-color: #e3f2fd;
  color: #495057;
}
button.p-paginator-page.p-paginator-element.p-link.p-paginator-page-start.p-highlight {
  background-color: transparent;
  border: 0 none;
  color: #6c757d;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  -webkit-transition: -webkit-box-shadow 0.2s;
  transition: -webkit-box-shadow 0.2s;
  transition: box-shadow 0.2s;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
  border-radius: 3px;
}
button.p-paginator-page.p-paginator-element.p-link.p-paginator-page-end {
  background-color: transparent;
  border: 0 none;
  color: #6c757d;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  -webkit-transition: -webkit-box-shadow 0.2s;
  transition: -webkit-box-shadow 0.2s;
  transition: box-shadow 0.2s;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
  border-radius: 3px;
}
/* new style */
.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 1rem 0.2rem !important;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #495057;
  background: #f8f9fa;
  -webkit-transition: -webkit-box-shadow 0.2s;
  transition: -webkit-box-shadow 0.2s;
  transition: box-shadow 0.2s;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
}
.btn {
  --bs-btn-padding-x: 0.5rem !important;
  --bs-btn-padding-y: 0.1rem !important;
}

.p-menubar-root-list > .p-menuitem-active > .p-submenu-list {
  z-index: 999;
}

.zoom:hover {
  margin: -17px;
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(1.5);
  transition: transform 0.2s;
  width: 80px;
  height: 80px;
  border-radius: 5px;
  z-index: 99999;
  position: absolute;
}
