/* .navbar.bg-light {
    background-color: #c8c8c9  !important;} */
    *{padding: 0;
      margin: 0;
    box-sizing: border-box;}
    .statuse{text-align: left;}
    .status-icon{text-align: right;}
    .state{text-align: left;}
    .fake-icone{text-align: right;}
  
  .qr-detail tbody, td, tfoot, th, thead, tr {
    border-color: inherit;
    border-style: solid;
    /* width: 50%; */
    margin: 2px;
    padding: 5px;
    border-width: 0;
    gap: 2px;
  }
  
  #wrap2 {
      margin: 20px auto;
      text-align: center;
  }
  /* i.fa.fa-download {
    margin-left: 12px;
  }
   */
  .btn-slide, .btn-slide2 {
      position: relative;
      display: inline-block;
      height: 50px;
      width: 220px;
      line-height: 50px;
      padding: 0;
      border-radius: 50px;
      background: #fdfdfd;
      border: 2px solid #0099cc;
      margin: 10px;
      transition: .5s;
  }
  
  .btn-slide2 {
      border: 2px solid #efa666;
  }
  
  .btn-slide2:hover {
      background-color: #efa666;
  }
  
  .btn-slide:hover span.circle, .btn-slide2:hover span.circle2 {
      left: 100%;
      margin-left: -45px;
      background-color: #fdfdfd;
      color: #0099cc;
  }
  
  .btn-slide2:hover span.circle2 {
      color: #efa666;
  }
  
  .btn-slide:hover span.title, .btn-slide2:hover span.title2 {
      left: 40px;
      opacity: 0;
  }
  
  .btn-slide:hover span.title-hover, .btn-slide2:hover span.title-hover2 {
      opacity: 1;
      left: 40px;
  }
  
  .btn-slide span.circle, .btn-slide2 span.circle2 {
      display: block;
      background-color: #0099cc;
      color: #fff;
      position: absolute;
      float: left;
      margin: 5px;
      line-height: 42px;
      height: 40px;
      width: 40px;
      top: 0;
      left: 0;
      transition: .5s;
      border-radius: 50%;
  }
  
  .btn-slide2 span.circle2 {
      background-color: #efa666;
  }
  
  .btn-slide span.title,
    .btn-slide span.title-hover, .btn-slide2 span.title2,
    .btn-slide2 span.title-hover2 {
      position: absolute;
      left: 90px;
      text-align: center;
      margin: 0 auto;
      font-size: 16px;
      font-weight: bold;
      color: #30abd5;
      transition: .5s;
  }
  
  .btn-slide2 span.title2,
    .btn-slide2 span.title-hover2 {
      color: #efa666;
      left: 50px;
    }
  
  .btn-slide span.title-hover, .btn-slide2 span.title-hover2 {
      left: 80px;
      opacity: 0;
  }
  
  .btn-slide span.title-hover, .btn-slide2 span.title-hover2 {
      color: #fff;
  }
  
      .header-logo {
        text-align: left;
      }
      .genuine-icon {
        text-align: right;
      }
      
      .shadow1 {
        box-shadow: 0 2.8px 2.2px rgb(0 0 0 / 3%), 0 6.7px 5.3px rgb(0 0 0 / 5%),
          0 12.5px 10px rgb(0 0 0 / 6%), 0 22.3px 17.9px rgb(0 0 0 / 7%),
          0 41.8px 33.4px rgb(0 0 0 / 9%), 0 100px 80px rgb(0 0 0 / 12%);
      }
      .pauth form {
        background: transparent;
        padding: 1rem;
    }
      .brand .navbar-brand {
        padding-top: 0rem !important;
        padding-bottom: 0rem !important;
      }
      .bg-light {
        background-color: #e3eded !important;
      }
      .bg-dark1 {
        background-color: rgba(0, 11, 30, 0.8);
        position: fixed;
        bottom: 0;
        width: 100%;
      }
      .genuine-img {
        margin-left: 0px !important;
        margin-right: 0px !important;
      }
      .genuine-img img {
        width: 10%;
      }
      
      .pa-back {
        background: url("https://ubuntuhandbook.org/wp-content/uploads/2017/10/gdm-login-background.jpg")
          no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        background-size: cover;
        -o-background-size: cover;
        height: 100vh;
      }
      .card.border-0.pap {
        border-radius: 10px;
        margin-top: 5rem;
        border: 5px solid #ccc;
    }
      .bg-image {
        background-image: url("./../assets/images/verified.png");
        background-size: cover;
        background-position: center center;
      }
      .Genuine {
        color: #008000;
        font-size: 22px;
        line-height: 50px;
        letter-spacing: 2px;
      }
      .invalid {
        color: #cf622f;
        font-size: 25px !important;
        line-height: 40px;
        letter-spacing: 1px;
      }
      .status {
        font-size: 25px !important;
    line-height: 50px;
    text-transform: uppercase;
    font-weight: 600;

      }
      .container-fluid.pa {
        width: 60%;
        margin-bottom: 5rem;
      }
      .thankfor-buy {
        font-size: 18px;
        text-align: center;
      }
      .feedback-head {
        /* background: linear-gradient(90deg, #cacfd5 0%, #595c5c 100%); */
        background: #3b93a1;
        color: #fff;
        line-height: 30px;
        padding: 10px;
        font-size: 20px;
        text-align: center;
      }
      .customer-care {
        text-align: left;
        padding-right: 30px;
      }
      .social-icon {
        text-align: right;
        padding-right: 30px;
      }
      .mandatory {
        color: #f00;
      }
      .custom-btn {
        width: 200px;
        height: 40px;
        color: #fff;
        border-radius: 5px;
        padding: 10px 25px;
        font-family: "Lato", sans-serif;
        font-weight: 500;
        background: transparent;
        cursor: pointer;
        transition: all 0.3s ease;
        position: relative;
        display: inline-block;
        box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
          7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
        outline: none;
      }
      
      /* 7 */
      .btn-7 {
        background: linear-gradient(0deg, rgb(1 108 20) 0%, rgb(28 185 127) 100%);
        line-height: 42px;
        padding: 0;
        border: none;
      }
      .btn-7 span {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
      }
      .btn-7:before,
      .btn-7:after {
        position: absolute;
        content: "";
        right: 0;
        bottom: 0;
        background: rgba(1, 108, 20);
        box-shadow: -7px -7px 20px 0px rgba(255, 255, 255, 0.9),
          -4px -4px 5px 0px rgba(255, 255, 255, 0.9),
          7px 7px 20px 0px rgba(0, 0, 0, 0.2), 4px 4px 5px 0px rgba(0, 0, 0, 0.3);
        transition: all 0.3s ease;
      }
      .btn-7:before {
        height: 0%;
        width: 2px;
      }
      .btn-7:after {
        width: 0%;
        height: 2px;
      }
      .btn-7:hover {
        color: rgba(1, 108, 20);
        background: transparent;
      }
      .btn-7:hover:before {
        height: 100%;
      }
      .btn-7:hover:after {
        width: 100%;
      }
      .btn-7 span:before,
      .btn-7 span:after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        background: rgba(1, 108, 20);
        box-shadow: -7px -7px 20px 0px rgba(255, 255, 255, 0.9),
          -4px -4px 5px 0px rgba(255, 255, 255, 0.9),
          7px 7px 20px 0px rgba(0, 0, 0, 0.2), 4px 4px 5px 0px rgba(0, 0, 0, 0.3);
        transition: all 0.3s ease;
      }
      .btn-7 span:before {
        width: 2px;
        height: 0%;
      }
      .btn-7 span:after {
        height: 2px;
        width: 0%;
      }
      .btn-7 span:hover:before {
        height: 100%;
      }
      .btn-7 span:hover:after {
        width: 100%;
      }
      
      /* form -style*/
      * {
        box-sizing: border-box;
      }
      
      input[type="text"],
      select,
      textarea {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc !important;
        border-radius: 4px;
        resize: vertical;
      }
      
      label {
        padding: 12px 12px 12px 0;
        display: inline-block;
      }
      
      input[type="submit"] {
        background-color: #04aa6d;
        color: white;
        padding: 10px 20px;
        border: none;
        width: 70%;
        border-radius: 6px;
        cursor: pointer;
        float: right;
      }
      
      input[type="submit"]:hover {
        background-color: #45a049;
      }
      
      .col-25 {
        float: left;
        width: 25%;
        margin-top: 6px;
      }
      
      .col-75 {
        float: left;
        width: 75%;
        margin-top: 6px;
      }
      
      /* Clear floats after the columns */
      .row:after {
        content: "";
        display: table;
        clear: both;
      }
      .status-mobile{
        flex-direction: inherit !important;}
      .d-booton-download{
          display: flex !important;
          justify-content: end !important;}
      .status-align{text-align: left;}
      
      .pa-nav{font-size: 1.5rem;}
      .pa-logo{text-align: left;}
      /* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
      @media screen and (max-width: 600px) {
    .state{text-align:center ;}
    .fake-icone{text-align: center;}
        .statuse{text-align: center;}
        .status-icon{text-align: center;}
        .status {
          font-size: 22px !important;}
        .thankfor-buy {
          font-size: 15px;
          text-align: center;
      }
        .pa-nav {
          font-size: 1.1rem;
          text-align: center;
      }
        .pa-logo{text-align: center !important;}
        .Genuine{
          font-size: 20px;
          line-height: 50px;
      }
      
      .status {
        font-size: 16px;
        line-height: 50px;
      }
      .invalid {
        color: #cf622f;
        font-size: 23px  !important;
        line-height: 35px;
        letter-spacing: 1px;
        text-align: center;
      }
      .container-fluid.pa {
        width: 100%;
        margin-bottom: 5rem !important;
      }
      .status-mobile{display: flex;
           flex-direction: column !important;}
  
      .d-booton-download{
        display: flex !important;
        justify-content: center !important;}
        div#wrap2\ download\ d-booton-download {
          text-align: center;
      }
        .status-align{text-align: center;}
        .col-25,
        .col-75,
        input[type="submit"] {
          width: 100%;
          margin-top: 0;
        }
        .customer-care {
          text-align: center;
        }
      
        .social-icon {
          text-align: center;
        }
        .container-fluid.pa {
          width: 100%;
          margin-bottom: 5rem;
        }
      
        .header-logo {
          text-align: center;
        }
        .genuine-icon {
          text-align: center;
        }
      }
      