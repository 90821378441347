



.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #ffffff !important;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: #ffffff !important;
  margin-left: 0.5rem;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #000;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: #ffffff !important;

}
.p-menubar {
  padding: 0.2rem !important;
  background: #34643a !important;
  color: #ffffff !important;
  border-top: 5px solid #fbb317 !important;
  border-radius: 3px;
}

/* SIDEBAR-MENU-ARROW-ICON-UPDOWN */
.p-menubar
  .p-menubar-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  > .p-submenu-icon {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link
  > .p-submenu-icon {
  margin-left: auto;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.card {
  box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 1%),
    0 0.9375rem 1.40625rem rgb(4 9 20 / 1%),
    0 0.25rem 0.53125rem rgb(4 9 20 / 1%), 0 0.125rem 0.1875rem rgb(4 9 20 / 1%);
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(26, 54, 126, 0.125);
  border-radius: 0.25rem;
}
.card-header.card-header-tab .card-header-title {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.font-size-lg {
  font-size: 1.1rem !important;
}
.font-weight-normal {
  font-weight: 400 !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header {
  display: flex;
  align-items: center;
  border-bottom-width: 1px;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0.625rem;
  height: 3.5rem;
}
.card-header,
.card-title {
  text-transform: uppercase;
  color: rgba(13, 27, 62, 0.7);
  font-weight: 700;
  font-size: 0.88rem;
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  color: inherit;
  background-color: #fff;
  border-bottom: 1px solid rgba(26, 54, 126, 0.125);
}
.widget-chart.text-left .icon-wrapper {
  /* min-width: 54px; */
  margin: 0 1rem;
}
.icon-wrapper {
  width: 55px;
  height: 55px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}
.icon-wrapper {
  display: flex;
  align-content: center;
  align-items: center;
}
.icon-wrapper .icon-wrapper-bg {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 3;
}
.opacity-10 {
  opacity: 1 !important;
}
.bg-warning {
  background-color: #f7b924 !important;
}
.icon-wrapper i {
  margin: 0 auto;
  font-size: 2rem;
  position: relative;
  z-index: 5;
}
[class^="lnr-"],
[class*=" lnr-"] {
  font-family: linearicons-free;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.opacity-8 {
  opacity: 0.8 !important;
}
.text-dark {
  color: #343a40 !important;
}
.widget-chart.text-left .widget-chart-content {
  display: flex;
  flex-direction: column;
  align-content: center;
  flex: 1;
  position: relative;
}
.widget-chart .widget-chart-content {
  position: relative;
  z-index: 5;
}
.widget-chart .widget-subheading:first-child {
  font-size: 20px;
  margin-top: 0;
}
.widget-chart .widget-subheading {
  margin: -0.5rem 0 0;
  display: flex;
  opacity: 0.6;
  justify-content: center;
  text-transform: uppercase;
}
.widget-chart.text-left .widget-numbers {
  /* margin-left: 0; */
}
.widget-chart .widget-subheading + .widget-numbers {
  margin-top: 0.5rem;
}
.widget-chart .widget-numbers {
  font-weight: 700;
  font-size: 1.5rem;
  display: block;
  line-height: 1;
  margin: 1rem auto;
}
.widget-chart.text-left .widget-chart-content .widget-description {
  align-self: flex-start;
  font-size: 16px;
}

.widget-chart .widget-numbers + .widget-chart-flex,
.widget-chart .widget-numbers + .widget-description,
.widget-chart .widget-numbers + .widget-subheading {
  margin-top: -0.5rem;
}
.widget-chart .widget-description {
  margin: 1rem 0 0;
}
.opacity-8 {
  opacity: 0.8 !important;
}
.text-focus {
  color: #444054 !important;
}
.widget-chart.text-left {
  flex-direction: row;
  align-items: center;
}
.widget-chart {
  text-align: center;
  padding: 10px;
  position: relative;
  font-size: 14px !important;
}
.btn.btn-pill.btn-wide,
.btn.btn-pill {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.btn-primary.btn-shadow {
  box-shadow: 0 0.125rem 0.625rem rgb(63 106 216 / 40%),
    0 0.0625rem 0.125rem rgb(63 106 216 / 50%);
}

.box-shadowe {
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%) !important;
}
.count .card {
  box-shadow: none !important;
  /* border:none !important; */
}
.count .card:hover {
  background-color: #d8f7f1;
}

.para-heading {
  font-size: 18px;
  color: #ea8508;
  text-align: left;
}
.count .rounded-circle {
  border-radius: 50% !important;
}
.widget-description .fa {
  text-align: right !important;
}

.desinged-by {
  color: #000;
}
/* login-style*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
}
.bg-img {
  background: url("./../assets/images/login-bg.jpg");
  height: 100vh;
  background-size: cover;
  background-position: center;
}
.bg-img:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
}
.content {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 999;
  text-align: center;
  padding: 60px 32px;
  width: 450px;
  transform: translate(-50%, -50%);
  background: #ffffff55;
  /* background: rgba(255,255,255,0.04); */
  box-shadow: -1px 4px 28px 0px rgba(0, 0, 0, 0.75);
}
/* Responsiveness */
@media (max-width: 768px) {
  .content {
    width: 95% !important;
  }
}
.login-wraper .form-group {
  box-shadow: 0 4px 17px rgb(0 0 0 / 35%);
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #c1bbbb;
}

/* login-style-end*/
.boxx .cards {
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%) !important;
}
.bux {
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%) !important;
  background: #fff;
}
.bux .btn {
  padding: 0.2rem 0.1rem !important;
}

/* FontAwesome for working BootSnippet :> */

@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
#team {
  background: #fff !important;
}

/* .btn-primary:hover,
.btn-primary:focus {
    background-color: #108d6f;
    border-color: #108d6f;
    box-shadow: none;
    outline: none;
}

.btn-primary {
    color: #fff;
    background-color: #007b5e;
    border-color: #007b5e;
} */

section {
  padding: 20px 0 0 0;
}

section .section-title {
  text-align: center;
  color: #007b5e;
  margin-bottom: 50px;
  text-transform: uppercase;
}

#team .card {
  border: 2px solid #ccc;
  background: #ffffff;
  margin: 0 2px;
}

.image-flip:hover .backside,
.image-flip.hover .backside {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
  border-radius: 0.25rem;
}

.image-flip:hover .frontside,
.image-flip.hover .frontside {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.mainflip {
  -webkit-transition: 1s;
  -webkit-transform-style: preserve-3d;
  -ms-transition: 1s;
  -moz-transition: 1s;
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transition: 1s;
  transform-style: preserve-3d;
  position: relative;
}

.frontside {
  position: relative;
  -webkit-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  z-index: 2;
  margin-bottom: 30px;
}

.backside {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
  -moz-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
  box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
}

.frontside,
.backside {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 1s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 1s;
  -moz-transform-style: preserve-3d;
  -o-transition: 1s;
  -o-transform-style: preserve-3d;
  -ms-transition: 1s;
  -ms-transform-style: preserve-3d;
  transition: 1s;
  transform-style: preserve-3d;
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0rem 0;
}
.frontside .card,
.backside .card {
  min-height: 192px;
}
.last-box {
  min-height: 150px !important;
}

.backside .card a {
  font-size: 18px;
  color: #007b5e !important;
}

.quatity {
  font-size: 30px;
  /* position: absolute; */

  display: flex;
  justify-content: center;
  align-items: center;
  /* top: 50%; */
  /* text-align: center; */
  /* padding-left: 20%; */
}
.carpenter-scan {
  font-size: 1.3rem !important;
}
.frontside .card .card-title,
.backside .card .card-title {
  color: #ffffff !important;
  text-transform: uppercase;
  background: #034c52;

  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  padding: 10px;
}

.frontside .card .card-body img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.row.py-3 {
  /* justify-content: space-between; */
  padding: 10px;
}
.service-card {
  text-align: center;
  box-shadow: 0 2px 2px rgb(0 0 0 / 20%);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 0.1);
}
.service-card:hover {
  box-shadow: 4px 4px 4px rgb(58 105 243 / 30%);
  transform: translate(0, -8px);
  box-shadow: 0 40px 40px rgb(0 0 0 / 20%);
}

.box1 {
  background: #fff;
  padding: 30px 20px;
  margin: 15px 0;
  border-radius: 10px !important;
  box-shadow: 0 4px 1px 0 rgb(0 0 0 / 5%), 0 6px 20px 0 rgb(0 0 0 / 4%);
}
.box2 {
  background: #f1eeee;
  padding: 5px;
  margin: 15px 0;
  border-radius: 10px !important;
  box-shadow: 0 4px 1px 0 rgb(0 0 0 / 5%), 0 6px 20px 0 rgb(0 0 0 / 4%);
}
.flex-align-center span {
  font-size: 17px !important;
}
.icon-head {
  text-align: center;
  padding: 10px;
  text-transform: uppercase;
  background: #35404d;
  color: #e3fcfd;
  font-size: 15px;
  margin-bottom: 0rem;
}
.icon-head1 {
  text-align: center;
  padding: 10px;
  text-transform: uppercase;
  background: #2a3542;
  color: #e3fcfd;
  font-size: 18px;
}
.divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 2px solid rgba(0, 0, 0, 0.15);
}

.ancher {
  text-align: center;
}
.ancher a {
  color: #fff;
}
.ancher a:hover {
  color: #e2f9f6;
}
.card1 {
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%) !important;
  margin: 10px 0;
}
.box2 img {
  width: 100%;
}
.view-button {
  text-align: center;
  padding: 10px;
}
.textleft {
  text-align: left;
}
.textright {
  text-align: right;
}
.hovereffect {
  width: 100%;
  height: 100%;
  float: left;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: default;
  background: #42b078;
}

.hovereffect .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  padding: 50px 20px;
}

.hovereffect img {
  display: block;
  position: relative;
  max-width: none;
  width: calc(100% + 20px);
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.hovereffect:hover img {
  opacity: 0.4;
  filter: alpha(opacity=40);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.hovereffect h2 {
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  position: relative;
  font-size: 30px;
  overflow: hidden;
  padding: 0.5em 0;
  background-color: transparent;
}
.view-all {
  font-size: 20px;
}
.hovereffect h2:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #fff;
  content: "";
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.hovereffect:hover h2:after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.hovereffect a,
.hovereffect p {
  color: #fff;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.hovereffect:hover a,
.hovereffect:hover p {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.feature-name {
  font-weight: 700;
  font-size: 16px;
  margin: 1rem 0;
  display: inline-block;
  text-align: center;
}
.cards {
  text-align: center;
}
.card-heading {
  margin: 20px;
  font-size: 20px;
  text-align: center;
}
.cards p {
  font-size: 16px;
  font-weight: 400;
  text-align: justify;
}
.value {
  font-size: 30px;
}
.m-top {
  margin: 0 0 5rem 0;
}
.p-menubar .p-menuitem-link {
  padding: 0.9rem 1rem;
  color: #495057;
  border-radius: 0;
  -webkit-transition: -webkit-box-shadow 0.2s;
  transition: -webkit-box-shadow 0.2s;
  transition: box-shadow 0.2s;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  
}
.p-dialog .p-dialog-header {
  border-bottom: 2px solid #ffba00 !important;
  background: #34643a !important;
  /* background-image: linear-gradient(40deg, #e75121,rgb(255 255 255 / 64%), #e75121)!important; */
  color: #fff !important;
  padding: .5rem !important;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  padding-left: 0.5rem !important;
}
/* 
.p-button {
   padding: 7PX 1rem !important;} */
.btn-success {
  color: #fff;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2rem !important;
  height: 2rem !important;
  color: #ffffff !important;
  border: 0 none;
  margin: 5px 15px;
  border: 1px solid #fff !important;
  background: #028337 !important;
  border-radius: 20% !important;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}
.p-tabview .p-tabview-panels {
  background: #ffffff;
  padding: 1rem;
  border: 0 none;
  color: #495057;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
/* .p-dialog .p-dialog-content {
  background: #fe8c00; 
  background-color: rgb(235, 238, 242) !important;
  color: #ffffff;
  padding: 0 1.5rem 2rem 1.5rem;
}
.p-dialog .p-dialog-content {
  color: #495057;
  padding: 2rem !important;
} */
.p-input-icon-left > .p-inputtext {
  padding-left: 2rem !important;
}
/* responsiveness */
@media screen and (min-width: 679px) and (max-width: 1320px) {
  .btn-success {
    margin: 0 0px !important;
    padding: -10px !important;
    font-size: 12px !important;
  }
  .btn-primary {
    margin: 0 0px !important;
    padding: -10px !important;
    font-size: 12px !important;
  }
}

@media (max-width: 768px) {
  .p-dialog .p-dialog-content {
    color: #495057;
    padding: 1rem !important;
  }
  .m-top {
    margin: -2rem 0 5rem 0;
  }
  .quatity {
    font-size: 30px;
    /* position: absolute; */
    margin-top: 15% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    /* top: 50%; */
    /* text-align: center; */
    /* padding-left: 20%; */
  }
  .user-area {
    color: #222d32;
  }
  .service-card {
    text-align: center;
    box-shadow: 0 2px 2px rgb(0 0 0 / 20%);
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 0.1);
    margin: 0.5rem 0;
  }
  .state-overview .value p {
    margin: 0;
    padding: 0;
    color: #949495;
    font-size: 20px;
    font-weight: 400;
    margin: 10px;
  }
  .state-overview .value {
    width: 58%;
  }
  state-overview .symbol {
    width: 30%;
    padding: 35px 8px;
    border-radius: 4px 0px 0px 4px;
  }
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link
    .p-menuitem-text,
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link
    .p-menuitem-icon,
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link
    .p-submenu-icon {
    color: rgb(255 255 255 / 70%) !important;
    font-size: 18px;
  }
  p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link:not(.p-disabled):hover {
    background: #01454a;
  }
}
